import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080 1080" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">


<path fill="#F6FEFE" opacity="1.000000" stroke="none" 
	d="
M587.000000,1081.000000 
	C391.355865,1081.000000 196.211716,1081.000000 1.033782,1081.000000 
	C1.033782,721.064087 1.033782,361.128113 1.033782,1.096095 
	C360.894379,1.096095 720.788818,1.096095 1080.841553,1.096095 
	C1080.841553,360.999939 1080.841553,720.999939 1080.841553,1081.000000 
	C916.460999,1081.000000 751.980530,1081.000000 587.000000,1081.000000 
M240.313095,640.634277 
	C240.572540,640.514099 240.637146,640.326660 240.931625,639.720764 
	C240.987869,639.545837 241.044113,639.370972 241.745270,639.005554 
	C243.714493,636.962158 245.683716,634.918762 247.861359,632.928833 
	C247.861359,632.928833 247.896469,632.716553 248.537155,632.574402 
	C250.960831,630.024353 253.384506,627.474304 255.913956,624.969238 
	C255.913956,624.969238 255.940109,624.857239 256.596893,624.737183 
	C262.092346,619.506531 267.587799,614.275879 273.011139,609.011719 
	C273.011139,609.011719 273.044983,609.083618 273.708984,608.919250 
	C306.342896,580.674133 341.058014,555.427612 379.395325,534.885559 
	C380.276154,536.579285 380.935669,537.722717 381.482635,538.917664 
	C394.564240,567.496643 407.614532,596.089966 420.711853,624.661682 
	C430.405182,645.807678 440.156891,666.926880 449.902222,688.094666 
	C449.902222,688.094666 449.911621,688.135193 449.970886,688.867737 
	C454.849243,699.359558 459.727600,709.851318 464.798004,720.072754 
	C464.798004,720.072754 464.564392,720.308167 464.724060,721.040649 
	C466.248749,724.537354 467.988953,727.959412 469.234009,731.552979 
	C470.349945,734.773865 472.234802,735.676758 475.567108,735.667358 
	C514.802490,735.556824 554.038269,735.604370 593.273987,735.586548 
	C594.657898,735.585938 596.041626,735.339539 597.967957,735.156006 
	C596.673157,732.402405 595.704773,730.316040 594.713928,728.240417 
	C588.791931,715.835754 582.864807,703.433594 576.955261,690.423401 
	C576.647522,689.944092 576.339844,689.464783 575.985535,688.243286 
	C571.134094,678.045593 566.282715,667.847900 561.482544,657.879150 
	C561.482544,657.879150 561.464478,657.644653 561.292847,656.927429 
	C561.071106,656.301208 560.900391,655.651062 560.620483,655.052002 
	C536.566956,603.573059 512.500244,552.100342 488.457581,500.616394 
	C487.242523,498.014526 486.229462,495.318298 484.729950,491.725891 
	C502.388062,488.069305 519.029114,483.999268 535.886536,481.260956 
	C572.311035,475.344116 609.018066,474.010956 645.825623,476.687256 
	C658.570374,477.613953 671.267761,479.191406 684.622375,480.751953 
	C685.081604,480.751709 685.540894,480.751495 686.768494,481.012756 
	C699.350098,483.374969 712.018799,485.351166 724.498474,488.165558 
	C781.587158,501.040039 835.745483,521.913269 887.699036,548.671082 
	C889.218384,549.453613 890.935059,550.748291 891.559143,552.226562 
	C895.164551,560.766968 895.982727,569.587646 893.106812,578.540955 
	C888.545349,592.742065 878.089783,601.672913 865.205811,607.532471 
	C830.948303,623.112549 795.575562,623.121582 759.832642,613.431824 
	C745.174927,609.458191 732.416992,601.658752 720.262512,592.490906 
	C720.262512,592.490906 720.428650,592.470886 720.149841,591.895447 
	C717.454285,589.559692 714.758667,587.223999 712.041870,584.979431 
	C712.041870,584.979431 712.117371,584.924011 711.864929,584.298706 
	C709.571960,581.868469 707.278992,579.438232 705.000000,577.000000 
	C705.000000,577.000000 704.991760,577.013855 704.834106,576.342529 
	C701.160706,571.344971 697.487366,566.347473 693.765747,561.284302 
	C680.636780,570.479004 667.857056,579.364136 655.148376,588.349854 
	C645.039856,595.497192 635.018372,602.767517 625.005371,609.994812 
	C625.005371,609.994812 624.980530,609.953064 624.302063,610.121521 
	C616.976624,615.201538 609.651184,620.281555 601.321899,626.057739 
	C608.536255,636.390869 615.521973,646.396423 622.586853,656.429199 
	C622.586853,656.429199 622.561157,656.508911 622.857971,657.089233 
	C623.580750,657.996094 624.280945,658.922058 625.029663,659.806946 
	C636.743652,673.651794 649.489014,686.359314 665.002441,695.999268 
	C665.002441,695.999268 664.988464,695.999023 665.183167,696.651184 
	C685.732849,710.611328 708.109070,720.604736 732.172791,726.575989 
	C779.531250,738.327515 827.054688,738.352722 874.361267,726.591553 
	C887.304321,723.373718 899.656921,717.780823 912.182007,713.201904 
	C912.182007,713.201904 912.196289,713.325439 912.944458,713.235474 
	C924.411072,706.582336 936.343384,700.598816 947.190735,693.055176 
	C955.242798,687.455505 961.886841,679.831055 969.052795,673.049500 
	C969.052795,673.049500 969.111938,673.154785 969.759827,672.982422 
	C985.601685,656.058838 996.955811,636.547913 1003.157043,614.186768 
	C1013.855591,575.609070 1013.082153,537.586548 996.809692,500.524780 
	C995.637634,497.855316 993.285095,495.235931 990.800720,493.680786 
	C950.843506,468.668732 908.943787,447.426239 865.205078,429.858337 
	C854.604126,425.600403 843.731140,422.019989 832.942810,417.990448 
	C832.942810,417.990448 832.806763,418.035889 832.437988,417.468109 
	C791.604187,402.840057 749.789612,391.929199 706.971558,385.065369 
	C659.108337,377.392822 610.990906,375.096436 562.599487,378.974335 
	C519.453369,382.431915 477.269836,390.685181 436.005066,403.646881 
	C384.105621,419.949066 335.623657,443.481659 290.236786,473.381256 
	C278.884888,480.859619 268.020294,489.077728 256.994995,496.996033 
	C256.994995,496.996033 256.959656,496.929932 256.271576,497.038086 
	C245.829712,505.351624 235.387863,513.665161 225.004517,521.989441 
	C225.004517,521.989441 224.972290,521.939392 224.332382,522.167725 
	C197.410156,543.971008 172.600143,568.030640 148.940948,593.277832 
	C139.307877,603.557556 130.272491,614.397400 121.001793,624.999695 
	C121.001793,624.999695 120.979904,624.965454 120.356636,625.188354 
	C90.707588,659.906738 63.439964,696.400146 38.138802,734.531006 
	C38.973835,734.907898 39.270065,735.158630 39.566509,735.158813 
	C80.039497,735.191467 120.512527,735.221802 160.985367,735.147644 
	C162.262314,735.145325 164.034225,734.409790 164.736176,733.420593 
	C171.633087,723.701660 178.351837,713.856323 185.038757,704.059753 
	C185.038757,704.059753 185.087219,704.127625 185.635956,703.766968 
	C188.053680,700.492310 190.471390,697.217651 193.153564,693.786987 
	C193.153564,693.786987 193.153488,693.479980 193.788101,693.208557 
	C194.985260,691.601318 196.182419,689.994019 197.010040,688.302246 
	C197.010040,688.302246 197.382690,688.362061 197.953293,688.050049 
	C204.308319,680.368408 210.663330,672.686707 216.997604,665.000000 
	C216.997604,665.000000 217.006256,665.019775 217.626999,664.793945 
	C219.807083,662.233521 221.987167,659.673096 224.085938,657.075256 
	C224.085938,657.075256 224.079849,657.164612 224.653000,657.048706 
	C224.811325,656.714905 224.969650,656.381042 225.046158,656.061584 
	C225.046158,656.061584 225.089127,656.132629 225.685196,655.836670 
	C230.299957,650.879761 234.914719,645.922852 239.851471,640.906372 
	C239.851471,640.906372 239.978424,640.605225 240.313095,640.634277 
M945.122192,822.101257 
	C945.122192,822.101257 945.309692,822.328552 945.921082,822.024170 
	C947.568054,816.380737 950.339722,810.795898 950.637390,805.082214 
	C951.345337,791.488953 950.893921,777.834717 950.876099,764.205139 
	C950.874451,762.944519 950.624146,761.684082 950.491455,760.443481 
	C942.579041,760.443481 935.049866,760.443481 927.138794,760.443481 
	C927.138794,775.144592 927.230164,789.415283 927.102905,803.684143 
	C927.031860,811.649963 923.878662,815.303040 915.264648,817.140747 
	C914.485962,817.195862 913.707275,817.250916 912.210876,817.060730 
	C904.665344,815.453003 901.534485,812.134033 901.387085,804.465271 
	C901.131226,791.163086 901.216614,777.854126 901.196777,764.547974 
	C901.193359,762.248596 901.188538,760.038269 898.050537,760.024841 
	C891.422974,759.996582 884.795227,760.016296 876.928894,760.016296 
	C877.577576,778.340576 877.431396,796.288757 879.156433,814.055237 
	C880.082336,823.590088 887.546021,829.464905 897.253601,832.584656 
	C897.836670,832.682556 898.419678,832.780457 899.718994,833.209595 
	C905.133179,833.718567 910.547424,834.227539 916.637329,834.883911 
	C917.087341,834.804504 917.537292,834.725037 918.814331,834.826294 
	C921.261719,834.536987 923.758850,834.465088 926.147339,833.918396 
	C933.802856,832.166260 940.968994,829.499390 945.122192,822.101257 
M798.396606,778.746216 
	C798.450317,779.482544 798.503967,780.218872 798.262268,781.783508 
	C797.677063,791.326965 802.380127,797.639771 810.739807,801.335449 
	C814.656494,803.067017 818.897705,804.103638 823.045288,805.264099 
	C827.994202,806.648804 833.083801,807.585083 837.931030,809.245300 
	C839.389343,809.744690 840.983948,811.943542 841.125305,813.490967 
	C841.228333,814.618774 839.211548,816.803833 837.861084,817.062500 
	C828.119141,818.928711 821.512756,819.309631 818.816101,809.241211 
	C811.557678,809.241211 804.326355,809.241211 797.104065,809.241211 
	C797.252075,821.410217 801.728455,828.467651 811.960083,831.702393 
	C816.793823,833.230591 821.982422,833.636658 827.840149,834.790344 
	C829.563293,834.785767 831.286377,834.781250 833.902954,834.975830 
	C837.349731,834.667725 840.850647,834.644897 844.234253,834.003479 
	C861.530273,830.724792 870.062622,816.867310 864.068542,801.119202 
	C860.574036,794.086121 854.168579,791.124573 847.117676,789.235718 
	C840.386414,787.432434 833.525513,786.096313 826.835999,784.159607 
	C822.394653,782.873718 821.270935,779.316833 824.073486,776.901794 
	C828.756226,772.866516 838.553162,774.940918 841.245605,780.378113 
	C841.767944,781.432800 843.240540,782.656250 844.313416,782.693604 
	C850.585510,782.912048 856.869141,782.800720 863.063416,782.800720 
	C864.218018,775.465149 861.630066,769.341858 855.574097,765.346191 
	C844.103577,757.778259 831.446289,757.588684 818.604858,760.231384 
	C812.093628,761.571472 805.808594,763.901917 801.357483,770.340942 
	C800.458984,772.909607 799.560547,775.478333 798.396606,778.746216 
M668.900452,167.396988 
	C642.246277,194.848785 628.380310,227.920471 626.233948,265.906677 
	C624.661011,293.743744 629.510620,320.330719 643.940063,344.605499 
	C645.071716,346.509399 647.823364,348.283752 650.028015,348.578674 
	C662.558533,350.254974 675.179504,351.248138 687.718567,352.869232 
	C731.431152,358.520569 774.017700,369.113708 815.705872,383.215057 
	C872.780945,402.521149 927.119873,427.802460 978.574890,459.219879 
	C980.421143,460.347137 982.678284,460.801544 984.743896,461.569550 
	C985.119690,461.058472 985.495483,460.547363 985.871277,460.036285 
	C982.310669,454.363892 979.099487,448.433258 975.130432,443.062714 
	C955.355957,416.305786 929.028320,397.251617 900.792114,380.514954 
	C869.385803,361.899231 835.940979,347.447723 802.734558,332.497345 
	C786.202759,325.054260 769.469910,317.861755 755.498413,305.882843 
	C748.382751,299.782043 743.083435,292.482697 741.958435,282.798645 
	C739.836914,264.535095 747.614136,250.469147 761.860291,240.310150 
	C788.871155,221.048599 818.731018,219.381378 849.491882,229.123047 
	C871.236267,236.009308 887.112183,250.424026 897.605835,270.651367 
	C898.322571,270.408051 898.859924,270.347351 899.250549,270.075592 
	C926.130981,251.374344 953.022705,232.688873 979.785889,213.821060 
	C980.865173,213.060211 981.536377,210.244308 981.022339,208.919586 
	C972.513306,186.991562 959.655212,168.016083 941.760376,152.631638 
	C911.360168,126.496162 875.107849,115.294769 835.986816,112.098267 
	C806.403931,109.681091 777.136047,111.941895 748.539307,120.512207 
	C718.390869,129.547531 691.548889,144.211838 668.900452,167.396988 
M284.330170,163.754425 
	C276.653076,180.182114 268.972656,196.608231 261.299500,213.037766 
	C232.863785,273.923492 204.441666,334.815613 175.993149,395.695404 
	C144.801788,462.444916 113.625145,529.201294 82.365295,595.918762 
	C68.082771,626.401733 53.606480,656.793762 39.263229,687.248352 
	C38.600868,688.654724 38.381741,690.269836 37.956181,691.787781 
	C41.622375,688.933533 44.394920,685.774109 46.774258,682.342102 
	C69.258179,649.910645 93.283234,618.678955 119.210190,588.919312 
	C164.521164,536.910278 214.454636,490.022095 271.591583,451.072388 
	C273.902313,449.497192 275.913177,446.935852 277.126526,444.387695 
	C283.920197,430.120422 290.404785,415.706299 297.036224,401.361420 
	C301.978058,390.671326 306.982635,380.010223 312.224182,368.767975 
	C319.288330,384.228058 326.013031,398.945251 332.872894,413.958252 
	C366.258301,396.227386 400.387085,382.432922 435.914886,371.123779 
	C435.044464,369.037872 434.473846,367.513336 433.785370,366.043976 
	C423.345367,343.762604 412.871063,321.497253 402.441742,299.210876 
	C373.332214,237.007019 344.250977,174.789932 315.070740,112.619293 
	C314.407074,111.205360 312.699036,110.281631 311.478180,109.129219 
	C310.438965,110.253677 309.040283,111.207924 308.415100,112.528412 
	C300.447266,129.357269 292.590332,146.238647 284.330170,163.754425 
M979.521973,833.820618 
	C989.014648,833.820618 998.508484,833.909668 1007.999512,833.783203 
	C1012.809021,833.719116 1017.713867,833.784241 1022.398132,832.875549 
	C1030.877686,831.230774 1036.773682,824.492493 1037.638672,816.490356 
	C1038.679565,806.859924 1035.225220,800.684387 1026.445801,796.438599 
	C1025.951294,796.199463 1025.528076,795.812927 1024.588989,795.158691 
	C1030.541626,792.714905 1034.382080,789.131653 1035.483398,783.154846 
	C1037.843262,770.349060 1029.871948,760.407898 1016.589172,760.128479 
	C1004.770142,759.879883 992.941895,760.064514 981.117737,760.059265 
	C976.494141,760.057251 971.870483,760.058960 966.935608,760.058960 
	C966.935608,784.791931 966.935608,809.009216 966.935608,833.820312 
	C970.977356,833.820312 974.755493,833.820312 979.521973,833.820618 
M534.551758,827.051636 
	C536.082031,829.267639 537.462708,833.253662 539.168091,833.397766 
	C547.191406,834.075806 555.303833,833.699585 564.377502,833.699585 
	C558.803589,823.077209 553.678589,813.310181 548.491882,803.425659 
	C549.405212,802.708130 550.003601,802.141602 550.688782,801.714111 
	C560.347656,795.687012 563.953186,787.689941 561.667358,777.307312 
	C559.562805,767.748047 552.083252,760.748474 541.422485,760.368652 
	C525.467590,759.800232 509.475616,760.269775 493.499481,760.325806 
	C493.239166,760.326721 492.980072,760.682373 492.557007,760.992188 
	C492.557007,784.948730 492.557007,809.013977 492.557007,833.437866 
	C500.405518,833.437866 507.960632,833.437866 516.138062,833.437866 
	C516.138062,824.667725 516.138062,816.104553 516.138062,807.685669 
	C524.365967,806.077637 524.371399,806.074951 527.497375,812.468201 
	C529.759216,817.094055 532.004150,821.728149 534.551758,827.051636 
M337.591217,761.051086 
	C329.957611,760.584900 322.324036,760.118652 314.489319,759.640137 
	C314.489319,784.904175 314.489319,809.086731 314.489319,833.792419 
	C320.970062,833.792419 327.105164,833.580444 333.216522,833.870178 
	C337.023499,834.050598 338.092224,832.719666 337.965790,829.026123 
	C337.706177,821.442566 337.887817,813.843811 337.887817,806.220093 
	C346.933777,806.220093 355.298309,806.220093 364.166626,806.220093 
	C364.166626,815.529480 364.166626,824.428772 364.166626,833.798767 
	C370.633881,833.798767 376.603912,833.588989 382.550415,833.872498 
	C386.520630,834.061707 387.612915,832.693359 387.577698,828.755920 
	C387.389923,807.780273 387.496490,786.801941 387.479492,765.824585 
	C387.478058,764.042725 387.316986,762.260925 387.224091,760.356323 
	C379.329895,760.356323 371.928223,760.356323 363.782104,760.356323 
	C363.782104,769.354858 363.782104,778.048340 363.782104,786.652954 
	C354.780212,786.652954 346.530487,786.652954 337.817535,786.652954 
	C337.817535,778.203064 337.817535,770.075317 337.591217,761.051086 
M442.378906,758.889709 
	C433.429382,758.534546 424.701111,759.540588 416.752777,763.973389 
	C400.326385,773.134460 394.556946,800.656311 405.187317,818.545959 
	C411.458954,829.100342 421.369232,833.394409 433.000977,834.557739 
	C456.129608,836.870911 477.563507,829.115906 479.532532,801.298584 
	C480.498444,787.652466 478.210693,774.924438 466.323578,766.044189 
	C459.556213,760.988831 451.609070,759.318542 442.378906,758.889709 
M149.044510,813.395447 
	C151.149429,822.395569 156.782089,828.453613 165.236450,831.622009 
	C177.829666,836.341614 190.701187,836.352051 203.324615,831.867126 
	C213.645416,828.200317 220.137756,820.370178 220.690796,809.565002 
	C221.514526,793.470642 220.936935,777.304504 220.926056,761.168884 
	C220.925888,760.917969 220.546677,760.667297 220.400650,760.486267 
	C212.785126,760.486267 205.242538,760.486267 197.335785,760.486267 
	C197.335785,763.068481 197.341293,765.208740 197.334885,767.348999 
	C197.297028,780.001404 197.459213,792.658936 197.141632,805.304382 
	C196.947235,813.044067 192.305725,817.065247 184.594299,817.106567 
	C176.623260,817.149292 171.962067,813.087891 171.791702,805.108948 
	C171.511017,791.962097 171.666489,778.806152 171.615494,765.654175 
	C171.608658,763.891113 171.470306,762.128601 171.396088,760.439575 
	C163.252975,760.439575 155.698975,760.439575 147.730911,760.439575 
	C147.730911,774.967529 147.666122,789.095337 147.781158,803.221558 
	C147.806641,806.349792 148.467499,809.472839 149.044510,813.395447 
M122.882347,789.464600 
	C119.773323,780.875488 116.489136,772.343689 113.650368,763.666138 
	C112.658508,760.634277 111.071327,759.941284 108.232437,760.004517 
	C101.911926,760.145447 95.574219,760.273560 89.266762,759.951599 
	C85.592110,759.763977 83.972488,761.210022 82.804733,764.501709 
	C77.146263,780.451843 71.278206,796.327515 65.517357,812.241516 
	C63.005676,819.179871 60.572796,826.146729 57.858128,833.793579 
	C64.420914,833.793579 70.071800,833.476257 75.668510,833.890747 
	C80.422173,834.242615 83.297943,832.976135 83.754959,827.912292 
	C84.117592,823.894287 86.481056,822.885986 90.257195,823.126343 
	C95.063393,823.432129 99.940529,823.552734 104.720749,823.081055 
	C109.708809,822.588745 111.961189,824.462524 112.829483,829.272339 
	C113.128426,830.928223 115.008858,833.505310 116.286133,833.578430 
	C123.550209,833.994446 130.850388,833.780090 139.070129,833.780090 
	C133.591766,818.792480 128.366364,804.496948 122.882347,789.464600 
M763.989868,760.044861 
	C759.161072,760.050171 754.302856,760.389160 749.511719,759.967163 
	C744.696777,759.543030 741.969849,761.185059 739.823181,765.601074 
	C736.751343,771.920227 732.840149,777.831360 728.833496,784.693909 
	C724.315063,776.913879 720.454224,770.139709 716.437988,763.459045 
	C715.650696,762.149414 714.293762,760.273987 713.145996,760.234924 
	C704.951355,759.956360 696.742920,760.086304 687.692200,760.086304 
	C689.074585,762.528564 689.875732,764.131592 690.844543,765.625916 
	C698.529785,777.479797 706.356140,789.244507 713.862061,801.210388 
	C715.356323,803.592529 716.251160,806.689026 716.399414,809.509888 
	C716.757202,816.317810 716.401978,823.160645 716.603577,829.981506 
	C716.641174,831.255188 717.891846,833.550354 718.653687,833.580505 
	C725.711975,833.859741 732.785828,833.745483 740.172913,833.745483 
	C740.172913,824.892517 740.048523,816.911987 740.253418,808.939941 
	C740.310181,806.730164 740.869141,804.252991 742.010315,802.398621 
	C747.759949,793.056458 753.789978,783.887024 759.697876,774.641907 
	C762.664124,770.000122 765.580078,765.326172 768.788147,760.239685 
	C766.830505,760.146179 765.834167,760.098572 763.989868,760.044861 
M255.654007,828.127686 
	C255.654007,829.879822 255.654007,831.631958 255.654007,833.754700 
	C262.594208,833.754700 268.745789,833.501831 274.863892,833.845093 
	C278.833740,834.067749 279.566772,832.497131 279.530579,828.947815 
	C279.374329,813.637085 279.462708,798.323853 279.490845,783.011536 
	C279.493164,781.763550 279.712708,780.515991 279.870178,778.859619 
	C287.841431,778.859619 295.423096,778.859619 303.570404,778.859619 
	C303.570404,773.960876 303.345001,769.491272 303.638367,765.055908 
	C303.894623,761.181458 302.754395,759.938721 298.654877,759.981079 
	C277.852325,760.195801 257.046082,760.051453 236.241150,760.059265 
	C234.814026,760.059814 233.386978,760.275391 231.879974,760.397461 
	C231.879974,766.688660 231.879974,772.583069 231.879974,779.139282 
	C239.935638,779.139282 247.644684,779.139282 255.654266,779.139282 
	C255.654266,795.603210 255.654266,811.399780 255.654007,828.127686 
M625.484009,778.941406 
	C628.898987,778.941406 632.313965,778.941406 636.219666,778.941406 
	C636.219666,797.624817 636.219666,815.501892 636.219666,833.802551 
	C642.525208,833.802551 648.339417,833.553284 654.120361,833.884949 
	C658.403381,834.130737 659.902832,832.940613 659.816895,828.382141 
	C659.534485,813.404236 659.720337,798.417236 659.754089,783.433838 
	C659.757263,782.022644 659.956604,780.611816 660.095215,778.804810 
	C668.151184,778.804810 675.736877,778.804810 683.460449,778.804810 
	C683.460449,772.342957 683.460449,766.328125 683.460449,760.422241 
	C659.446167,760.422241 635.889648,760.422241 612.279297,760.422241 
	C612.279297,766.699463 612.279297,772.595581 612.279297,778.941833 
	C616.570312,778.941833 620.532349,778.941833 625.484009,778.941406 
M576.734924,763.600647 
	C576.734924,786.844788 576.734924,810.088989 576.734924,833.438782 
	C585.174561,833.438782 592.906616,833.438782 600.766785,833.438782 
	C600.766785,808.859619 600.766785,784.611755 600.766785,759.863464 
	C599.098022,759.863464 597.650085,759.864258 596.202148,759.863281 
	C590.539429,759.859619 584.871887,759.739258 579.219177,759.975159 
	C578.357483,760.011169 577.564575,761.696106 576.734924,763.600647 
z"/>
<path fill="#280FED" opacity="1.000000" stroke="none" 
	d="
M576.032104,688.985474 
	C576.339844,689.464783 576.647522,689.944092 576.688965,690.949463 
	C577.849792,695.423462 578.991211,699.504700 580.759766,703.293213 
	C585.301331,713.021912 590.121155,722.620728 595.271912,733.177673 
	C592.729248,733.500793 590.746399,733.970276 588.763000,733.972656 
	C551.931335,734.016968 515.099182,733.925537 478.268433,734.115540 
	C474.033264,734.137329 472.031891,732.583130 470.343567,729.000488 
	C468.876190,725.886719 466.525024,723.189453 464.564392,720.308167 
	C464.564392,720.308167 464.798004,720.072754 464.832245,719.798218 
	C464.899475,719.027771 465.113434,718.454407 464.938965,718.047485 
	C460.673004,708.096069 456.380859,698.155701 451.991394,688.258545 
	C451.862244,687.967285 450.629700,688.165283 449.911621,688.135193 
	C449.911621,688.135193 449.902222,688.094666 449.937592,687.665649 
	C449.777832,685.771179 449.890808,684.175842 449.336823,682.861755 
	C446.136749,675.271301 442.894684,667.694092 439.451019,660.211670 
	C432.257965,644.582581 424.855591,629.049744 417.679962,613.412781 
	C411.071838,599.012451 404.711090,584.498718 398.122131,570.089478 
	C393.252441,559.440125 388.021393,548.953247 383.293335,538.243042 
	C381.209351,533.522217 378.923065,532.292175 374.358154,534.873718 
	C370.772217,536.901672 367.154266,538.896240 363.714142,541.154785 
	C347.062592,552.087280 330.120117,562.622314 313.958038,574.240417 
	C300.598053,583.844238 288.157379,594.731323 275.395294,605.154968 
	C274.283752,606.062805 273.813232,607.755493 273.044983,609.083618 
	C273.044983,609.083618 273.011139,609.011719 272.677307,609.011353 
	C268.477509,612.179932 264.474365,615.199768 260.801788,618.578796 
	C258.881409,620.345703 257.541931,622.744080 255.940109,624.857239 
	C255.940109,624.857239 255.913956,624.969238 255.504059,624.964783 
	C251.439880,626.381042 248.778000,628.722717 247.896469,632.716553 
	C247.896469,632.716553 247.861359,632.928833 247.414886,632.931519 
	C244.970612,634.608826 242.972824,636.283447 240.640076,638.009155 
	C239.514069,639.030701 238.723022,640.001038 237.673279,641.173401 
	C235.941452,642.904846 234.468323,644.434326 232.626266,645.986694 
	C231.491989,647.004395 230.726639,647.999207 229.662445,649.160522 
	C227.938782,651.595581 226.513962,653.864075 225.089142,656.132629 
	C225.089127,656.132629 225.046158,656.061584 224.828552,656.149048 
	C224.433899,656.545898 224.256866,656.855286 224.079834,657.164612 
	C224.079849,657.164612 224.085938,657.075256 223.758789,657.080200 
	C221.289825,659.729980 219.148026,662.374878 217.006256,665.019775 
	C217.006256,665.019775 216.997604,665.000000 216.669220,664.989136 
	C215.562897,665.322693 214.784958,665.667175 213.784760,666.121582 
	C213.281021,666.436279 213.104523,666.708984 212.823410,667.313171 
	C207.867950,673.179260 203.057068,678.729187 198.439407,684.435486 
	C197.660019,685.398682 197.713852,687.036133 197.382690,688.362061 
	C197.382690,688.362061 197.010040,688.302246 196.869080,688.238159 
	C196.150482,688.441345 195.572830,688.708679 194.753876,689.150146 
	C194.331970,689.879578 194.151337,690.434814 193.707596,691.220337 
	C193.347473,692.127014 193.250488,692.803467 193.153488,693.479980 
	C193.153488,693.479980 193.153564,693.786987 192.657959,693.878845 
	C188.090912,696.163025 185.022110,699.053833 185.087219,704.127625 
	C185.087219,704.127625 185.038757,704.059753 184.741364,704.151367 
	C181.620605,707.815369 178.797226,711.387695 175.678574,714.994629 
	C174.932526,715.367249 174.481766,715.705200 173.862839,716.346924 
	C169.805222,722.117737 165.915787,727.584839 161.765625,733.036987 
	C161.331024,733.013611 161.157135,733.005066 160.690826,732.700928 
	C158.593536,732.273438 156.788727,732.027893 154.983749,732.026550 
	C117.633034,731.999146 80.282280,731.999451 42.982742,731.641479 
	C50.267467,720.481018 57.278034,709.515015 64.800186,698.912048 
	C71.673088,689.224243 79.000870,679.852173 86.308754,670.481750 
	C94.447105,660.046570 102.887184,649.846252 110.998161,639.390320 
	C114.576797,634.777039 117.667618,629.785339 120.979912,624.965454 
	C120.979904,624.965454 121.001793,624.999695 121.333130,624.982239 
	C123.188866,623.725403 124.895485,622.653015 126.208618,621.220032 
	C136.049194,610.481201 145.283691,599.127502 155.708923,588.996277 
	C177.694397,567.630737 200.302872,546.906616 222.598816,525.859070 
	C223.669357,524.848450 224.193634,523.259216 224.972290,521.939392 
	C224.972290,521.939392 225.004517,521.989441 225.349899,521.996704 
	C226.643143,521.733582 227.775604,521.708557 228.510818,521.155457 
	C237.742126,514.210938 246.944214,507.226105 256.049561,500.118073 
	C256.753510,499.568512 256.674652,498.016174 256.959656,496.929932 
	C256.959656,496.929932 256.994995,496.996033 257.342621,496.999878 
	C264.651398,492.627228 271.666992,488.334473 278.560608,483.854065 
	C288.227722,477.571136 297.727783,471.028961 307.447510,464.830048 
	C313.414917,461.024261 319.564484,457.476624 325.798676,454.122375 
	C338.743439,447.157532 351.674133,440.145233 364.854767,433.647705 
	C373.121002,429.572754 381.723846,426.111511 390.364136,422.878540 
	C410.019897,415.523926 429.567017,407.754944 449.589661,401.545258 
	C464.859619,396.809601 480.739868,393.954498 496.446472,390.741180 
	C509.359344,388.099396 522.341614,385.459412 535.426025,384.070557 
	C556.480042,381.835754 577.622437,379.755646 598.766907,379.245209 
	C619.143921,378.753296 639.582275,380.101501 659.971375,381.097046 
	C670.696106,381.620697 681.443787,382.660217 692.054443,384.290955 
	C706.088562,386.447937 720.028870,389.262207 733.952881,392.074402 
	C746.419983,394.592316 758.870850,397.245392 771.216003,400.295624 
	C781.629272,402.868500 791.899841,406.031403 802.195740,409.061798 
	C807.716248,410.686676 813.125366,412.689636 818.643372,414.323853 
	C823.320679,415.709076 828.082397,416.809448 832.806763,418.035889 
	C832.806763,418.035889 832.942810,417.990448 833.029297,418.401245 
	C840.197388,421.839661 847.267883,424.893555 854.362915,427.889465 
	C868.774902,433.974976 883.279297,439.849457 897.592224,446.158813 
	C907.252686,450.417267 916.716125,455.141052 926.148560,459.893005 
	C936.155762,464.934540 946.064453,470.176910 955.934875,475.482697 
	C962.164917,478.831573 968.283752,482.393341 974.383179,485.977478 
	C980.350220,489.483795 986.250916,493.105225 992.121277,496.771240 
	C992.898621,497.256683 993.561951,498.218933 993.878235,499.103394 
	C997.604431,509.521240 1002.116699,519.754578 1004.743652,530.448730 
	C1010.116699,552.322021 1009.680603,574.705872 1005.583069,596.605225 
	C1002.442139,613.391174 997.884644,630.193115 988.065796,644.739197 
	C982.554810,652.903381 976.520752,660.713135 970.833862,668.761475 
	C969.952576,670.008789 969.670898,671.679749 969.111938,673.154785 
	C969.111938,673.154785 969.052795,673.049500 968.748718,673.063110 
	C967.019836,674.160400 965.440857,675.091492 964.194336,676.351562 
	C952.731628,687.938416 939.261353,696.759155 925.285950,704.926025 
	C920.813843,707.539429 916.553528,710.515381 912.196289,713.325439 
	C912.196289,713.325439 912.182007,713.201904 911.808167,713.144409 
	C907.984314,714.102844 904.487793,714.984070 901.092102,716.157227 
	C883.099548,722.373535 865.070129,728.312500 845.956421,730.260437 
	C832.513977,731.630371 819.190552,734.143372 805.560425,733.082886 
	C790.538025,731.914185 775.466736,731.324768 760.464478,729.958801 
	C741.599915,728.241150 723.498108,722.764221 705.852966,716.336182 
	C695.953796,712.729919 687.059509,706.407959 677.606567,701.514221 
	C673.538879,699.408447 669.202698,697.821594 664.988464,695.999023 
	C664.988464,695.999023 665.002441,695.999268 664.983032,695.654236 
	C660.453796,691.293030 655.797852,687.427612 651.466370,683.227539 
	C643.784546,675.778931 636.361938,668.063110 628.684875,660.609375 
	C626.950989,658.926025 624.618225,657.859436 622.561157,656.508911 
	C622.561157,656.508911 622.586853,656.429199 622.697998,656.037109 
	C622.776001,654.994873 622.985840,654.179565 622.673096,653.719482 
	C618.264954,647.233459 613.703857,640.850220 609.363647,634.319885 
	C607.606934,631.676636 606.294434,628.738098 604.817383,626.003662 
	C610.879272,621.973816 616.303406,618.565491 621.474487,614.808716 
	C623.009094,613.693787 623.831665,611.598938 624.980530,609.953064 
	C624.980530,609.953064 625.005371,609.994812 625.369019,609.998413 
	C627.099243,609.543518 628.665344,609.371094 629.799927,608.579834 
	C636.775757,603.714539 643.592224,598.620544 650.578186,593.770264 
	C663.319763,584.924133 676.315430,576.431213 688.828735,567.276489 
	C692.717712,564.431396 694.112183,565.228821 696.567627,568.632812 
	C698.854309,571.802612 702.143799,574.249023 704.991760,577.013855 
	C704.991760,577.013855 705.000000,577.000000 704.985474,577.373535 
	C706.463684,581.025024 707.376465,584.880432 712.117371,584.924011 
	C712.117371,584.924011 712.041870,584.979431 712.051880,585.288574 
	C714.850830,587.888733 717.639709,590.179810 720.428650,592.470886 
	C720.428650,592.470886 720.262512,592.490906 720.297668,592.878784 
	C722.505676,595.473022 724.343262,598.228210 726.907288,599.794128 
	C740.574341,608.140686 754.691772,615.168823 770.791565,618.168213 
	C786.878662,621.165283 802.794434,622.588684 819.159424,621.199158 
	C835.357483,619.823853 851.053772,616.808105 865.521484,609.468628 
	C872.658813,605.847717 879.726196,601.251709 885.314148,595.602234 
	C894.648560,586.165161 897.951904,574.039368 895.928833,560.790771 
	C895.191650,555.963379 895.416870,549.974243 890.441101,547.510254 
	C874.621033,539.676147 858.615906,532.183228 842.442810,525.105774 
	C831.248962,520.207275 819.739502,515.989197 808.227783,511.867432 
	C791.285461,505.801239 774.455383,499.217804 757.136108,494.462189 
	C737.568359,489.089203 717.534607,485.388489 697.648682,481.222229 
	C693.897583,480.436371 689.889587,480.876740 686.000122,480.751282 
	C685.540894,480.751495 685.081604,480.751709 684.198608,480.284698 
	C682.781616,479.256195 681.829773,478.302948 680.788818,478.192963 
	C666.971680,476.733063 653.168518,474.817993 639.308228,474.175781 
	C624.347351,473.482544 609.330078,473.994843 594.337402,474.007416 
	C589.008789,474.011871 583.625549,473.620728 578.362000,474.229523 
	C563.768127,475.917480 549.161499,477.662292 534.677368,480.086639 
	C519.030762,482.705597 503.490295,485.993134 487.969482,489.298798 
	C485.097900,489.910339 482.231720,492.001434 483.995483,495.844910 
	C490.748352,510.560181 497.621765,525.220276 504.462372,539.895203 
	C510.669861,553.211975 516.935547,566.501770 523.096741,579.839905 
	C528.333252,591.176208 533.377502,602.601685 538.652405,613.919800 
	C544.713867,626.925720 550.867432,639.890259 557.163086,652.783813 
	C558.077942,654.657471 560.005493,656.036682 561.464478,657.644653 
	C561.464478,657.644653 561.482544,657.879150 561.315430,658.171692 
	C561.283142,659.581421 561.151001,660.820251 561.595581,661.796326 
	C564.766418,668.757507 567.939270,675.723145 571.370300,682.557434 
	C572.542236,684.891907 574.456787,686.853516 576.032104,688.985474 
z"/>
<path fill="#0B7C2F" opacity="1.000000" stroke="none" 
	d="
M669.149658,167.143295 
	C691.548889,144.211838 718.390869,129.547531 748.539307,120.512207 
	C777.136047,111.941895 806.403931,109.681091 835.986816,112.098267 
	C875.107849,115.294769 911.360168,126.496162 941.760376,152.631638 
	C959.655212,168.016083 972.513306,186.991562 981.022339,208.919586 
	C981.536377,210.244308 980.865173,213.060211 979.785889,213.821060 
	C953.022705,232.688873 926.130981,251.374344 899.250549,270.075592 
	C898.859924,270.347351 898.322571,270.408051 897.605835,270.651367 
	C887.112183,250.424026 871.236267,236.009308 849.491882,229.123047 
	C818.731018,219.381378 788.871155,221.048599 761.860291,240.310150 
	C747.614136,250.469147 739.836914,264.535095 741.958435,282.798645 
	C743.083435,292.482697 748.382751,299.782043 755.498413,305.882843 
	C769.469910,317.861755 786.202759,325.054260 802.734558,332.497345 
	C835.940979,347.447723 869.385803,361.899231 900.792114,380.514954 
	C929.028320,397.251617 955.355957,416.305786 975.130432,443.062714 
	C979.099487,448.433258 982.310669,454.363892 985.871277,460.036285 
	C985.495483,460.547363 985.119690,461.058472 984.743896,461.569550 
	C982.678284,460.801544 980.421143,460.347137 978.574890,459.219879 
	C927.119873,427.802460 872.780945,402.521149 815.705872,383.215057 
	C774.017700,369.113708 731.431152,358.520569 687.718567,352.869232 
	C675.179504,351.248138 662.558533,350.254974 650.028015,348.578674 
	C647.823364,348.283752 645.071716,346.509399 643.940063,344.605499 
	C629.510620,320.330719 624.661011,293.743744 626.233948,265.906677 
	C628.380310,227.920471 642.246277,194.848785 669.149658,167.143295 
z"/>
<path fill="#0B7C2F" opacity="1.000000" stroke="none" 
	d="
M284.517700,163.430542 
	C292.590332,146.238647 300.447266,129.357269 308.415100,112.528412 
	C309.040283,111.207924 310.438965,110.253677 311.478180,109.129227 
	C312.699036,110.281631 314.407074,111.205360 315.070740,112.619293 
	C344.250977,174.789932 373.332214,237.007019 402.441742,299.210876 
	C412.871063,321.497253 423.345367,343.762604 433.785370,366.043976 
	C434.473846,367.513336 435.044464,369.037872 435.914886,371.123779 
	C400.387085,382.432922 366.258301,396.227386 332.872894,413.958252 
	C326.013031,398.945251 319.288330,384.228058 312.224182,368.767975 
	C306.982635,380.010223 301.978058,390.671326 297.036224,401.361420 
	C290.404785,415.706299 283.920197,430.120422 277.126526,444.387695 
	C275.913177,446.935852 273.902313,449.497192 271.591583,451.072388 
	C214.454636,490.022095 164.521164,536.910278 119.210190,588.919312 
	C93.283234,618.678955 69.258179,649.910645 46.774258,682.342102 
	C44.394920,685.774109 41.622375,688.933533 37.956181,691.787781 
	C38.381741,690.269836 38.600868,688.654724 39.263229,687.248352 
	C53.606480,656.793762 68.082771,626.401733 82.365295,595.918762 
	C113.625145,529.201294 144.801788,462.444916 175.993149,395.695404 
	C204.441666,334.815613 232.863785,273.923492 261.299500,213.037766 
	C268.972656,196.608231 276.653076,180.182114 284.517700,163.430542 
z"/>
<path fill="#2A12E8" opacity="1.000000" stroke="none" 
	d="
M979.027832,833.820435 
	C974.755493,833.820312 970.977356,833.820312 966.935608,833.820312 
	C966.935608,809.009216 966.935608,784.791931 966.935608,760.058960 
	C971.870483,760.058960 976.494141,760.057251 981.117737,760.059265 
	C992.941895,760.064514 1004.770142,759.879883 1016.589172,760.128479 
	C1029.871948,760.407898 1037.843262,770.349060 1035.483398,783.154846 
	C1034.382080,789.131653 1030.541626,792.714905 1024.588989,795.158691 
	C1025.528076,795.812927 1025.951294,796.199463 1026.445801,796.438599 
	C1035.225220,800.684387 1038.679565,806.859924 1037.638672,816.490356 
	C1036.773682,824.492493 1030.877686,831.230774 1022.398132,832.875549 
	C1017.713867,833.784241 1012.809021,833.719116 1007.999512,833.783203 
	C998.508484,833.909668 989.014648,833.820618 979.027832,833.820435 
M1006.176208,803.979065 
	C1000.957947,803.979065 995.739746,803.979065 990.689209,803.979065 
	C990.689209,808.681458 990.689209,812.555786 990.689209,816.610840 
	C996.236877,816.610840 1001.356750,816.535706 1006.473267,816.634033 
	C1010.746704,816.716125 1013.630798,815.247559 1013.779236,810.555420 
	C1013.922546,806.026306 1011.642761,803.805969 1006.176208,803.979065 
M1010.498901,778.004578 
	C1003.991882,777.087891 997.484802,776.171265 990.565308,775.196533 
	C990.565308,780.584290 990.565308,784.479187 990.565308,788.877197 
	C995.443359,788.877197 1000.053772,788.922302 1004.662903,788.866516 
	C1011.385559,788.785156 1013.504944,785.321411 1010.498901,778.004578 
z"/>
<path fill="#117E34" opacity="1.000000" stroke="none" 
	d="
M534.404175,826.705078 
	C532.004150,821.728149 529.759216,817.094055 527.497375,812.468201 
	C524.371399,806.074951 524.365967,806.077637 516.138062,807.685669 
	C516.138062,816.104553 516.138062,824.667725 516.138062,833.437866 
	C507.960632,833.437866 500.405518,833.437866 492.557007,833.437866 
	C492.557007,809.013977 492.557007,784.948730 492.557007,760.992188 
	C492.980072,760.682373 493.239166,760.326721 493.499481,760.325806 
	C509.475616,760.269775 525.467590,759.800232 541.422485,760.368652 
	C552.083252,760.748474 559.562805,767.748047 561.667358,777.307312 
	C563.953186,787.689941 560.347656,795.687012 550.688782,801.714111 
	C550.003601,802.141602 549.405212,802.708130 548.491882,803.425659 
	C553.678589,813.310181 558.803589,823.077209 564.377502,833.699585 
	C555.303833,833.699585 547.191406,834.075806 539.168091,833.397766 
	C537.462708,833.253662 536.082031,829.267639 534.404175,826.705078 
M516.224854,777.834656 
	C516.178894,780.994995 515.885010,784.181763 516.218750,787.301514 
	C516.338623,788.422424 517.921326,790.213196 518.919800,790.271484 
	C523.392822,790.532898 527.915527,790.515198 532.382202,790.141846 
	C536.278381,789.816162 538.302124,787.253174 538.090881,783.328674 
	C537.884766,779.498596 535.877686,777.065491 531.848267,777.012329 
	C526.861328,776.946472 521.871521,777.099670 516.224854,777.834656 
z"/>
<path fill="#107D33" opacity="1.000000" stroke="none" 
	d="
M337.704376,761.499390 
	C337.817535,770.075317 337.817535,778.203064 337.817535,786.652954 
	C346.530487,786.652954 354.780212,786.652954 363.782104,786.652954 
	C363.782104,778.048340 363.782104,769.354858 363.782104,760.356323 
	C371.928223,760.356323 379.329895,760.356323 387.224091,760.356323 
	C387.316986,762.260925 387.478058,764.042725 387.479492,765.824585 
	C387.496490,786.801941 387.389923,807.780273 387.577698,828.755920 
	C387.612915,832.693359 386.520630,834.061707 382.550415,833.872498 
	C376.603912,833.588989 370.633881,833.798767 364.166626,833.798767 
	C364.166626,824.428772 364.166626,815.529480 364.166626,806.220093 
	C355.298309,806.220093 346.933777,806.220093 337.887817,806.220093 
	C337.887817,813.843811 337.706177,821.442566 337.965790,829.026123 
	C338.092224,832.719666 337.023499,834.050598 333.216522,833.870178 
	C327.105164,833.580444 320.970062,833.792419 314.489319,833.792419 
	C314.489319,809.086731 314.489319,784.904175 314.489319,759.640137 
	C322.324036,760.118652 329.957611,760.584900 337.704376,761.499390 
z"/>
<path fill="#0F7D32" opacity="1.000000" stroke="none" 
	d="
M442.856140,758.899414 
	C451.609070,759.318542 459.556213,760.988831 466.323578,766.044189 
	C478.210693,774.924438 480.498444,787.652466 479.532532,801.298584 
	C477.563507,829.115906 456.129608,836.870911 433.000977,834.557739 
	C421.369232,833.394409 411.458954,829.100342 405.187317,818.545959 
	C394.556946,800.656311 400.326385,773.134460 416.752777,763.973389 
	C424.701111,759.540588 433.429382,758.534546 442.856140,758.899414 
M446.332977,816.229309 
	C451.308228,814.311768 454.361755,810.468689 454.822693,805.427917 
	C455.362701,799.523560 455.464325,793.425171 454.590912,787.585205 
	C453.545105,780.592896 448.125366,776.787415 440.655334,776.348816 
	C433.814148,775.947144 426.970612,779.336182 425.916229,785.773132 
	C424.710907,793.131775 424.579956,801.110962 426.138275,808.356750 
	C427.803101,816.097656 434.560577,818.190979 446.332977,816.229309 
z"/>
<path fill="#117E34" opacity="1.000000" stroke="none" 
	d="
M148.940155,812.996826 
	C148.467499,809.472839 147.806641,806.349792 147.781158,803.221558 
	C147.666122,789.095337 147.730911,774.967529 147.730911,760.439575 
	C155.698975,760.439575 163.252975,760.439575 171.396088,760.439575 
	C171.470306,762.128601 171.608658,763.891113 171.615494,765.654175 
	C171.666489,778.806152 171.511017,791.962097 171.791702,805.108948 
	C171.962067,813.087891 176.623260,817.149292 184.594299,817.106567 
	C192.305725,817.065247 196.947235,813.044067 197.141632,805.304382 
	C197.459213,792.658936 197.297028,780.001404 197.334885,767.348999 
	C197.341293,765.208740 197.335785,763.068481 197.335785,760.486267 
	C205.242538,760.486267 212.785126,760.486267 220.400650,760.486267 
	C220.546677,760.667297 220.925888,760.917969 220.926056,761.168884 
	C220.936935,777.304504 221.514526,793.470642 220.690796,809.565002 
	C220.137756,820.370178 213.645416,828.200317 203.324615,831.867126 
	C190.701187,836.352051 177.829666,836.341614 165.236450,831.622009 
	C156.782089,828.453613 151.149429,822.395569 148.940155,812.996826 
z"/>
<path fill="#107D33" opacity="1.000000" stroke="none" 
	d="
M123.011658,789.833008 
	C128.366364,804.496948 133.591766,818.792480 139.070129,833.780090 
	C130.850388,833.780090 123.550209,833.994446 116.286133,833.578430 
	C115.008858,833.505310 113.128426,830.928223 112.829483,829.272339 
	C111.961189,824.462524 109.708809,822.588745 104.720749,823.081055 
	C99.940529,823.552734 95.063393,823.432129 90.257195,823.126343 
	C86.481056,822.885986 84.117592,823.894287 83.754959,827.912292 
	C83.297943,832.976135 80.422173,834.242615 75.668510,833.890747 
	C70.071800,833.476257 64.420914,833.793579 57.858128,833.793579 
	C60.572796,826.146729 63.005676,819.179871 65.517357,812.241516 
	C71.278206,796.327515 77.146263,780.451843 82.804733,764.501709 
	C83.972488,761.210022 85.592110,759.763977 89.266762,759.951599 
	C95.574219,760.273560 101.911926,760.145447 108.232437,760.004517 
	C111.071327,759.941284 112.658508,760.634277 113.650368,763.666138 
	C116.489136,772.343689 119.773323,780.875488 123.011658,789.833008 
M93.293617,795.720398 
	C92.242195,799.296387 91.190781,802.872314 90.108978,806.551636 
	C95.612900,806.551636 100.449539,806.551636 105.908005,806.551636 
	C103.340492,797.873108 100.949142,789.790100 98.557800,781.707031 
	C98.193115,781.738098 97.828430,781.769165 97.463745,781.800232 
	C96.163605,786.188049 94.863472,790.575867 93.293617,795.720398 
z"/>
<path fill="#2B14E8" opacity="1.000000" stroke="none" 
	d="
M833.009521,834.776733 
	C831.286377,834.781250 829.563293,834.785767 827.297607,834.327881 
	C821.468201,832.283691 816.087830,830.956665 810.916809,829.059204 
	C803.506653,826.340210 799.810181,820.569519 799.239319,812.855164 
	C799.173950,811.972290 800.702271,810.189270 801.555237,810.148315 
	C806.829773,809.894958 812.122498,810.020996 816.338135,810.020996 
	C818.291870,813.064209 819.204224,815.848267 821.124695,817.200073 
	C825.457092,820.249573 830.751953,820.724304 835.823669,819.817810 
	C839.207581,819.212952 842.455078,817.254395 842.733032,813.143250 
	C842.998718,809.214661 840.204956,807.675720 836.897644,806.860718 
	C830.313110,805.238220 823.710632,803.687744 817.134155,802.033569 
	C806.784241,799.430115 801.537109,793.503418 799.893860,782.822510 
	C799.789978,782.147522 799.018494,781.575256 798.557617,780.955200 
	C798.503967,780.218872 798.450317,779.482544 798.809692,778.279663 
	C800.133240,775.222290 801.043701,772.631470 801.954224,770.040649 
	C805.808594,763.901917 812.093628,761.571472 818.604858,760.231384 
	C831.446289,757.588684 844.103577,757.778259 855.574097,765.346191 
	C861.630066,769.341858 864.218018,775.465149 863.063416,782.800720 
	C856.869141,782.800720 850.585510,782.912048 844.313416,782.693604 
	C843.240540,782.656250 841.767944,781.432800 841.245605,780.378113 
	C838.553162,774.940918 828.756226,772.866516 824.073486,776.901794 
	C821.270935,779.316833 822.394653,782.873718 826.835999,784.159607 
	C833.525513,786.096313 840.386414,787.432434 847.117676,789.235718 
	C854.168579,791.124573 860.574036,794.086121 864.073730,801.933044 
	C863.945984,807.531433 865.119934,812.852356 863.420166,816.993835 
	C859.309692,827.008972 850.981079,832.139404 840.085938,833.058228 
	C837.695862,833.259827 835.366699,834.184814 833.009521,834.776733 
z"/>
<path fill="#2A13E9" opacity="1.000000" stroke="none" 
	d="
M899.002747,832.878296 
	C898.419678,832.780457 897.836670,832.682556 897.070557,831.972168 
	C895.143921,830.191345 893.384399,829.045898 891.659546,827.850403 
	C885.372498,823.492920 880.169006,818.249939 880.052551,810.048584 
	C879.842346,795.246155 880.003845,780.438599 879.941406,765.633545 
	C879.931213,763.219727 880.579834,761.882996 883.301086,761.966553 
	C887.455994,762.094116 891.632141,761.809326 895.767029,762.123840 
	C896.906433,762.210571 898.851685,763.786255 898.869751,764.709045 
	C899.114929,777.265991 899.634216,789.858582 898.942139,802.379150 
	C898.434631,811.560364 904.798035,819.203491 912.928589,817.306030 
	C913.707275,817.250916 914.485962,817.195862 915.937134,817.423096 
	C923.971252,818.074402 928.924988,813.053345 928.972229,804.925293 
	C929.046570,792.126892 929.084412,779.326538 928.922424,766.529724 
	C928.879272,763.117432 929.845459,761.682617 933.416199,761.936096 
	C937.054749,762.194458 940.731689,762.124817 944.380859,761.958862 
	C947.845764,761.801270 949.107971,763.273010 949.056763,766.679321 
	C948.926880,775.320679 949.279480,783.975647 948.940613,792.605591 
	C948.643677,800.165588 947.751221,807.707703 946.946289,815.238953 
	C946.690430,817.633057 945.871094,819.967041 945.309692,822.328552 
	C945.309692,822.328552 945.122192,822.101257 944.867737,822.132324 
	C937.116577,828.535034 928.716187,832.808899 918.705688,833.420654 
	C918.442261,833.436768 918.225708,834.220093 917.987305,834.645630 
	C917.537292,834.725037 917.087341,834.804504 916.012695,834.576050 
	C909.926331,833.804871 904.464539,833.341614 899.002747,832.878296 
z"/>
<path fill="#107D33" opacity="1.000000" stroke="none" 
	d="
M764.413818,760.047913 
	C765.834167,760.098572 766.830505,760.146179 768.788147,760.239685 
	C765.580078,765.326172 762.664124,770.000122 759.697876,774.641907 
	C753.789978,783.887024 747.759949,793.056458 742.010315,802.398621 
	C740.869141,804.252991 740.310181,806.730164 740.253418,808.939941 
	C740.048523,816.911987 740.172913,824.892517 740.172913,833.745483 
	C732.785828,833.745483 725.711975,833.859741 718.653687,833.580505 
	C717.891846,833.550354 716.641174,831.255188 716.603577,829.981506 
	C716.401978,823.160645 716.757202,816.317810 716.399414,809.509888 
	C716.251160,806.689026 715.356323,803.592529 713.862061,801.210388 
	C706.356140,789.244507 698.529785,777.479797 690.844543,765.625916 
	C689.875732,764.131592 689.074585,762.528564 687.692200,760.086304 
	C696.742920,760.086304 704.951355,759.956360 713.145996,760.234924 
	C714.293762,760.273987 715.650696,762.149414 716.437988,763.459045 
	C720.454224,770.139709 724.315063,776.913879 728.833496,784.693909 
	C732.840149,777.831360 736.751343,771.920227 739.823181,765.601074 
	C741.969849,761.185059 744.696777,759.543030 749.511719,759.967163 
	C754.302856,760.389160 759.161072,760.050171 764.413818,760.047913 
z"/>
<path fill="#0F7D33" opacity="1.000000" stroke="none" 
	d="
M255.654144,827.661987 
	C255.654266,811.399780 255.654266,795.603210 255.654266,779.139282 
	C247.644684,779.139282 239.935638,779.139282 231.879974,779.139282 
	C231.879974,772.583069 231.879974,766.688660 231.879974,760.397461 
	C233.386978,760.275391 234.814026,760.059814 236.241150,760.059265 
	C257.046082,760.051453 277.852325,760.195801 298.654877,759.981079 
	C302.754395,759.938721 303.894623,761.181458 303.638367,765.055908 
	C303.345001,769.491272 303.570404,773.960876 303.570404,778.859619 
	C295.423096,778.859619 287.841431,778.859619 279.870178,778.859619 
	C279.712708,780.515991 279.493164,781.763550 279.490845,783.011536 
	C279.462708,798.323853 279.374329,813.637085 279.530579,828.947815 
	C279.566772,832.497131 278.833740,834.067749 274.863892,833.845093 
	C268.745789,833.501831 262.594208,833.754700 255.654007,833.754700 
	C255.654007,831.631958 255.654007,829.879822 255.654144,827.661987 
z"/>
<path fill="#117D34" opacity="1.000000" stroke="none" 
	d="
M624.989197,778.941650 
	C620.532349,778.941833 616.570312,778.941833 612.279297,778.941833 
	C612.279297,772.595581 612.279297,766.699463 612.279297,760.422241 
	C635.889648,760.422241 659.446167,760.422241 683.460449,760.422241 
	C683.460449,766.328125 683.460449,772.342957 683.460449,778.804810 
	C675.736877,778.804810 668.151184,778.804810 660.095215,778.804810 
	C659.956604,780.611816 659.757263,782.022644 659.754089,783.433838 
	C659.720337,798.417236 659.534485,813.404236 659.816895,828.382141 
	C659.902832,832.940613 658.403381,834.130737 654.120361,833.884949 
	C648.339417,833.553284 642.525208,833.802551 636.219666,833.802551 
	C636.219666,815.501892 636.219666,797.624817 636.219666,778.941406 
	C632.313965,778.941406 628.898987,778.941406 624.989197,778.941650 
z"/>
<path fill="#0D7C30" opacity="1.000000" stroke="none" 
	d="
M576.737427,763.109558 
	C577.564575,761.696106 578.357483,760.011169 579.219177,759.975159 
	C584.871887,759.739258 590.539429,759.859619 596.202148,759.863281 
	C597.650085,759.864258 599.098022,759.863464 600.766785,759.863464 
	C600.766785,784.611755 600.766785,808.859619 600.766785,833.438782 
	C592.906616,833.438782 585.174561,833.438782 576.734924,833.438782 
	C576.734924,810.088989 576.734924,786.844788 576.737427,763.109558 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M832.622375,417.752014 
	C828.082397,416.809448 823.320679,415.709076 818.643372,414.323853 
	C813.125366,412.689636 807.716248,410.686676 802.195740,409.061798 
	C791.899841,406.031403 781.629272,402.868500 771.216003,400.295624 
	C758.870850,397.245392 746.419983,394.592316 733.952881,392.074402 
	C720.028870,389.262207 706.088562,386.447937 692.054443,384.290955 
	C681.443787,382.660217 670.696106,381.620697 659.971375,381.097046 
	C639.582275,380.101501 619.143921,378.753296 598.766907,379.245209 
	C577.622437,379.755646 556.480042,381.835754 535.426025,384.070557 
	C522.341614,385.459412 509.359344,388.099396 496.446472,390.741180 
	C480.739868,393.954498 464.859619,396.809601 449.589661,401.545258 
	C429.567017,407.754944 410.019897,415.523926 390.364136,422.878540 
	C381.723846,426.111511 373.121002,429.572754 364.854767,433.647705 
	C351.674133,440.145233 338.743439,447.157532 325.798676,454.122375 
	C319.564484,457.476624 313.414917,461.024261 307.447510,464.830048 
	C297.727783,471.028961 288.227722,477.571136 278.560608,483.854065 
	C271.666992,488.334473 264.651398,492.627228 257.310974,496.979797 
	C268.020294,489.077728 278.884888,480.859619 290.236786,473.381256 
	C335.623657,443.481659 384.105621,419.949066 436.005066,403.646881 
	C477.269836,390.685181 519.453369,382.431915 562.599487,378.974335 
	C610.990906,375.096436 659.108337,377.392822 706.971558,385.065369 
	C749.789612,391.929199 791.604187,402.840057 832.622375,417.752014 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M686.384277,480.882019 
	C689.889587,480.876740 693.897583,480.436371 697.648682,481.222229 
	C717.534607,485.388489 737.568359,489.089203 757.136108,494.462189 
	C774.455383,499.217804 791.285461,505.801239 808.227783,511.867432 
	C819.739502,515.989197 831.248962,520.207275 842.442810,525.105774 
	C858.615906,532.183228 874.621033,539.676147 890.441101,547.510254 
	C895.416870,549.974243 895.191650,555.963379 895.928833,560.790771 
	C897.951904,574.039368 894.648560,586.165161 885.314148,595.602234 
	C879.726196,601.251709 872.658813,605.847717 865.521484,609.468628 
	C851.053772,616.808105 835.357483,619.823853 819.159424,621.199158 
	C802.794434,622.588684 786.878662,621.165283 770.791565,618.168213 
	C754.691772,615.168823 740.574341,608.140686 726.907288,599.794128 
	C724.343262,598.228210 722.505676,595.473022 720.378357,592.900879 
	C732.416992,601.658752 745.174927,609.458191 759.832642,613.431824 
	C795.575562,623.121582 830.948303,623.112549 865.205811,607.532471 
	C878.089783,601.672913 888.545349,592.742065 893.106812,578.540955 
	C895.982727,569.587646 895.164551,560.766968 891.559143,552.226562 
	C890.935059,550.748291 889.218384,549.453613 887.699036,548.671082 
	C835.745483,521.913269 781.587158,501.040039 724.498474,488.165558 
	C712.018799,485.351166 699.350098,483.374969 686.384277,480.882019 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M561.378662,657.286011 
	C560.005493,656.036682 558.077942,654.657471 557.163086,652.783813 
	C550.867432,639.890259 544.713867,626.925720 538.652405,613.919800 
	C533.377502,602.601685 528.333252,591.176208 523.096741,579.839905 
	C516.935547,566.501770 510.669861,553.211975 504.462372,539.895203 
	C497.621765,525.220276 490.748352,510.560181 483.995483,495.844910 
	C482.231720,492.001434 485.097900,489.910339 487.969482,489.298798 
	C503.490295,485.993134 519.030762,482.705597 534.677368,480.086639 
	C549.161499,477.662292 563.768127,475.917480 578.362000,474.229523 
	C583.625549,473.620728 589.008789,474.011871 594.337402,474.007416 
	C609.330078,473.994843 624.347351,473.482544 639.308228,474.175781 
	C653.168518,474.817993 666.971680,476.733063 680.788818,478.192963 
	C681.829773,478.302948 682.781616,479.256195 683.880859,480.144531 
	C671.267761,479.191406 658.570374,477.613953 645.825623,476.687256 
	C609.018066,474.010956 572.311035,475.344116 535.886536,481.260956 
	C519.029114,483.999268 502.388062,488.069305 484.729950,491.725891 
	C486.229462,495.318298 487.242523,498.014526 488.457581,500.616394 
	C512.500244,552.100342 536.566956,603.573059 560.620483,655.052002 
	C560.900391,655.651062 561.071106,656.301208 561.378662,657.286011 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M969.435913,673.068604 
	C969.670898,671.679749 969.952576,670.008789 970.833862,668.761475 
	C976.520752,660.713135 982.554810,652.903381 988.065796,644.739197 
	C997.884644,630.193115 1002.442139,613.391174 1005.583069,596.605225 
	C1009.680603,574.705872 1010.116699,552.322021 1004.743652,530.448730 
	C1002.116699,519.754578 997.604431,509.521240 993.878235,499.103394 
	C993.561951,498.218933 992.898621,497.256683 992.121277,496.771240 
	C986.250916,493.105225 980.350220,489.483795 974.383179,485.977478 
	C968.283752,482.393341 962.164917,478.831573 955.934875,475.482697 
	C946.064453,470.176910 936.155762,464.934540 926.148560,459.893005 
	C916.716125,455.141052 907.252686,450.417267 897.592224,446.158813 
	C883.279297,439.849457 868.774902,433.974976 854.362915,427.889465 
	C847.267883,424.893555 840.197388,421.839661 833.049500,418.470032 
	C843.731140,422.019989 854.604126,425.600403 865.205078,429.858337 
	C908.943787,447.426239 950.843506,468.668732 990.800720,493.680786 
	C993.285095,495.235931 995.637634,497.855316 996.809692,500.524780 
	C1013.082153,537.586548 1013.855591,575.609070 1003.157043,614.186768 
	C996.955811,636.547913 985.601685,656.058838 969.435913,673.068604 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M273.376984,609.001465 
	C273.813232,607.755493 274.283752,606.062805 275.395294,605.154968 
	C288.157379,594.731323 300.598053,583.844238 313.958038,574.240417 
	C330.120117,562.622314 347.062592,552.087280 363.714142,541.154785 
	C367.154266,538.896240 370.772217,536.901672 374.358154,534.873718 
	C378.923065,532.292175 381.209351,533.522217 383.293335,538.243042 
	C388.021393,548.953247 393.252441,559.440125 398.122131,570.089478 
	C404.711090,584.498718 411.071838,599.012451 417.679962,613.412781 
	C424.855591,629.049744 432.257965,644.582581 439.451019,660.211670 
	C442.894684,667.694092 446.136749,675.271301 449.336823,682.861755 
	C449.890808,684.175842 449.777832,685.771179 449.927765,687.647339 
	C440.156891,666.926880 430.405182,645.807678 420.711853,624.661682 
	C407.614532,596.089966 394.564240,567.496643 381.482635,538.917664 
	C380.935669,537.722717 380.276154,536.579285 379.395325,534.885559 
	C341.058014,555.427612 306.342896,580.674133 273.376984,609.001465 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M120.668274,625.076904 
	C117.667618,629.785339 114.576797,634.777039 110.998161,639.390320 
	C102.887184,649.846252 94.447105,660.046570 86.308754,670.481750 
	C79.000870,679.852173 71.673088,689.224243 64.800186,698.912048 
	C57.278034,709.515015 50.267467,720.481018 42.773201,731.755737 
	C42.245949,732.424316 42.073093,732.687500 42.287594,733.310547 
	C43.391777,733.742737 44.202198,733.993347 45.012741,733.993713 
	C82.780159,734.012756 120.547607,734.019104 158.314987,733.969421 
	C159.552505,733.967773 160.789246,733.371216 162.026352,733.051941 
	C165.915787,727.584839 169.805222,722.117737 174.157318,716.313477 
	C175.071243,715.637512 175.522522,715.298767 175.973816,714.960022 
	C178.797226,711.387695 181.620605,707.815369 184.782578,704.144897 
	C178.351837,713.856323 171.633087,723.701660 164.736176,733.420593 
	C164.034225,734.409790 162.262314,735.145325 160.985367,735.147644 
	C120.512527,735.221802 80.039497,735.191467 39.566509,735.158813 
	C39.270065,735.158630 38.973835,734.907898 38.138802,734.531006 
	C63.439964,696.400146 90.707588,659.906738 120.668274,625.076904 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M665.085815,696.325073 
	C669.202698,697.821594 673.538879,699.408447 677.606567,701.514221 
	C687.059509,706.407959 695.953796,712.729919 705.852966,716.336182 
	C723.498108,722.764221 741.599915,728.241150 760.464478,729.958801 
	C775.466736,731.324768 790.538025,731.914185 805.560425,733.082886 
	C819.190552,734.143372 832.513977,731.630371 845.956421,730.260437 
	C865.070129,728.312500 883.099548,722.373535 901.092102,716.157227 
	C904.487793,714.984070 907.984314,714.102844 911.857422,713.182373 
	C899.656921,717.780823 887.304321,723.373718 874.361267,726.591553 
	C827.054688,738.352722 779.531250,738.327515 732.172791,726.575989 
	C708.109070,720.604736 685.732849,710.611328 665.085815,696.325073 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M464.644226,720.674438 
	C466.525024,723.189453 468.876190,725.886719 470.343567,729.000488 
	C472.031891,732.583130 474.033264,734.137329 478.268433,734.115540 
	C515.099182,733.925537 551.931335,734.016968 588.763000,733.972656 
	C590.746399,733.970276 592.729248,733.500793 595.271912,733.177673 
	C590.121155,722.620728 585.301331,713.021912 580.759766,703.293213 
	C578.991211,699.504700 577.849792,695.423462 576.681030,691.253052 
	C582.864807,703.433594 588.791931,715.835754 594.713928,728.240417 
	C595.704773,730.316040 596.673157,732.402405 597.967957,735.156006 
	C596.041626,735.339539 594.657898,735.585938 593.273987,735.586548 
	C554.038269,735.604370 514.802490,735.556824 475.567108,735.667358 
	C472.234802,735.676758 470.349945,734.773865 469.234009,731.552979 
	C467.988953,727.959412 466.248749,724.537354 464.644226,720.674438 
z"/>
<path fill="#311ECC" opacity="1.000000" stroke="none" 
	d="
M912.569702,817.183350 
	C904.798035,819.203491 898.434631,811.560364 898.942139,802.379150 
	C899.634216,789.858582 899.114929,777.265991 898.869751,764.709045 
	C898.851685,763.786255 896.906433,762.210571 895.767029,762.123840 
	C891.632141,761.809326 887.455994,762.094116 883.301086,761.966553 
	C880.579834,761.882996 879.931213,763.219727 879.941406,765.633545 
	C880.003845,780.438599 879.842346,795.246155 880.052551,810.048584 
	C880.169006,818.249939 885.372498,823.492920 891.659546,827.850403 
	C893.384399,829.045898 895.143921,830.191345 896.921265,831.708801 
	C887.546021,829.464905 880.082336,823.590088 879.156433,814.055237 
	C877.431396,796.288757 877.577576,778.340576 876.928894,760.016296 
	C884.795227,760.016296 891.422974,759.996582 898.050537,760.024841 
	C901.188538,760.038269 901.193359,762.248596 901.196777,764.547974 
	C901.216614,777.854126 901.131226,791.163086 901.387085,804.465271 
	C901.534485,812.134033 904.665344,815.453003 912.569702,817.183350 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M224.652344,522.053589 
	C224.193634,523.259216 223.669357,524.848450 222.598816,525.859070 
	C200.302872,546.906616 177.694397,567.630737 155.708923,588.996277 
	C145.283691,599.127502 136.049194,610.481201 126.208618,621.220032 
	C124.895485,622.653015 123.188866,623.725403 121.314819,624.973633 
	C130.272491,614.397400 139.307877,603.557556 148.940948,593.277832 
	C172.600143,568.030640 197.410156,543.971008 224.652344,522.053589 
z"/>
<path fill="#3728C8" opacity="1.000000" stroke="none" 
	d="
M798.409912,781.369385 
	C799.018494,781.575256 799.789978,782.147522 799.893860,782.822510 
	C801.537109,793.503418 806.784241,799.430115 817.134155,802.033569 
	C823.710632,803.687744 830.313110,805.238220 836.897644,806.860718 
	C840.204956,807.675720 842.998718,809.214661 842.733032,813.143250 
	C842.455078,817.254395 839.207581,819.212952 835.823669,819.817810 
	C830.751953,820.724304 825.457092,820.249573 821.124695,817.200073 
	C819.204224,815.848267 818.291870,813.064209 816.338135,810.020996 
	C812.122498,810.020996 806.829773,809.894958 801.555237,810.148315 
	C800.702271,810.189270 799.173950,811.972290 799.239319,812.855164 
	C799.810181,820.569519 803.506653,826.340210 810.916809,829.059204 
	C816.087830,830.956665 821.468201,832.283691 826.882690,834.208374 
	C821.982422,833.636658 816.793823,833.230591 811.960083,831.702393 
	C801.728455,828.467651 797.252075,821.410217 797.104065,809.241211 
	C804.326355,809.241211 811.557678,809.241211 818.816101,809.241211 
	C821.512756,819.309631 828.119141,818.928711 837.861084,817.062500 
	C839.211548,816.803833 841.228333,814.618774 841.125305,813.490967 
	C840.983948,811.943542 839.389343,809.744690 837.931030,809.245300 
	C833.083801,807.585083 827.994202,806.648804 823.045288,805.264099 
	C818.897705,804.103638 814.656494,803.067017 810.739807,801.335449 
	C802.380127,797.639771 797.677063,791.326965 798.409912,781.369385 
z"/>
<path fill="#311ECC" opacity="1.000000" stroke="none" 
	d="
M945.615356,822.176392 
	C945.871094,819.967041 946.690430,817.633057 946.946289,815.238953 
	C947.751221,807.707703 948.643677,800.165588 948.940613,792.605591 
	C949.279480,783.975647 948.926880,775.320679 949.056763,766.679321 
	C949.107971,763.273010 947.845764,761.801270 944.380859,761.958862 
	C940.731689,762.124817 937.054749,762.194458 933.416199,761.936096 
	C929.845459,761.682617 928.879272,763.117432 928.922424,766.529724 
	C929.084412,779.326538 929.046570,792.126892 928.972229,804.925293 
	C928.924988,813.053345 923.971252,818.074402 916.323669,817.480164 
	C923.878662,815.303040 927.031860,811.649963 927.102905,803.684143 
	C927.230164,789.415283 927.138794,775.144592 927.138794,760.443481 
	C935.049866,760.443481 942.579041,760.443481 950.491455,760.443481 
	C950.624146,761.684082 950.874451,762.944519 950.876099,764.205139 
	C950.893921,777.834717 951.345337,791.488953 950.637390,805.082214 
	C950.339722,810.795898 947.568054,816.380737 945.615356,822.176392 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M704.912964,576.678223 
	C702.143799,574.249023 698.854309,571.802612 696.567627,568.632812 
	C694.112183,565.228821 692.717712,564.431396 688.828735,567.276489 
	C676.315430,576.431213 663.319763,584.924133 650.578186,593.770264 
	C643.592224,598.620544 636.775757,603.714539 629.799927,608.579834 
	C628.665344,609.371094 627.099243,609.543518 625.345459,609.992554 
	C635.018372,602.767517 645.039856,595.497192 655.148376,588.349854 
	C667.857056,579.364136 680.636780,570.479004 693.765747,561.284302 
	C697.487366,566.347473 701.160706,571.344971 704.912964,576.678223 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M912.570374,713.280457 
	C916.553528,710.515381 920.813843,707.539429 925.285950,704.926025 
	C939.261353,696.759155 952.731628,687.938416 964.194336,676.351562 
	C965.440857,675.091492 967.019836,674.160400 968.801392,673.092651 
	C961.886841,679.831055 955.242798,687.455505 947.190735,693.055176 
	C936.343384,700.598816 924.411072,706.582336 912.570374,713.280457 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M624.641296,610.037292 
	C623.831665,611.598938 623.009094,613.693787 621.474487,614.808716 
	C616.303406,618.565491 610.879272,621.973816 604.817383,626.003662 
	C606.294434,628.738098 607.606934,631.676636 609.363647,634.319885 
	C613.703857,640.850220 618.264954,647.233459 622.673096,653.719482 
	C622.985840,654.179565 622.776001,654.994873 622.658386,656.023560 
	C615.521973,646.396423 608.536255,636.390869 601.321899,626.057739 
	C609.651184,620.281555 616.976624,615.201538 624.641296,610.037292 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M622.709595,656.799072 
	C624.618225,657.859436 626.950989,658.926025 628.684875,660.609375 
	C636.361938,668.063110 643.784546,675.778931 651.466370,683.227539 
	C655.797852,687.427612 660.453796,691.293030 664.981689,695.661194 
	C649.489014,686.359314 636.743652,673.651794 625.029663,659.806946 
	C624.280945,658.922058 623.580750,657.996094 622.709595,656.799072 
z"/>
<path fill="#3728C8" opacity="1.000000" stroke="none" 
	d="
M833.456238,834.876282 
	C835.366699,834.184814 837.695862,833.259827 840.085938,833.058228 
	C850.981079,832.139404 859.309692,827.008972 863.420166,816.993835 
	C865.119934,812.852356 863.945984,807.531433 864.142517,802.273560 
	C870.062622,816.867310 861.530273,830.724792 844.234253,834.003479 
	C840.850647,834.644897 837.349731,834.667725 833.456238,834.876282 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M256.615631,496.984009 
	C256.674652,498.016174 256.753510,499.568512 256.049561,500.118073 
	C246.944214,507.226105 237.742126,514.210938 228.510818,521.155457 
	C227.775604,521.708557 226.643143,521.733582 225.320648,521.991333 
	C235.387863,513.665161 245.829712,505.351624 256.615631,496.984009 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M449.941254,688.501465 
	C450.629700,688.165283 451.862244,687.967285 451.991394,688.258545 
	C456.380859,698.155701 460.673004,708.096069 464.938965,718.047485 
	C465.113434,718.454407 464.899475,719.027771 464.736206,719.933350 
	C459.727600,709.851318 454.849243,699.359558 449.941254,688.501465 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M576.008789,688.614380 
	C574.456787,686.853516 572.542236,684.891907 571.370300,682.557434 
	C567.939270,675.723145 564.766418,668.757507 561.595581,661.796326 
	C561.151001,660.820251 561.283142,659.581421 561.289795,658.057251 
	C566.282715,667.847900 571.134094,678.045593 576.008789,688.614380 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M197.667999,688.206055 
	C197.713852,687.036133 197.660019,685.398682 198.439407,684.435486 
	C203.057068,678.729187 207.867950,673.179260 213.037170,667.193237 
	C213.728897,666.602783 213.911087,666.336792 214.007019,666.011658 
	C214.784958,665.667175 215.562897,665.322693 216.679596,664.991638 
	C210.663330,672.686707 204.308319,680.368408 197.667999,688.206055 
z"/>
<path fill="#311ECC" opacity="1.000000" stroke="none" 
	d="
M918.400757,834.735962 
	C918.225708,834.220093 918.442261,833.436768 918.705688,833.420654 
	C928.716187,832.808899 937.116577,828.535034 944.980591,822.226990 
	C940.968994,829.499390 933.802856,832.166260 926.147339,833.918396 
	C923.758850,834.465088 921.261719,834.536987 918.400757,834.735962 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M256.268494,624.797241 
	C257.541931,622.744080 258.881409,620.345703 260.801788,618.578796 
	C264.474365,615.199768 268.477509,612.179932 272.713379,609.028076 
	C267.587799,614.275879 262.092346,619.506531 256.268494,624.797241 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M232.995209,645.963806 
	C234.468323,644.434326 235.941452,642.904846 237.940643,641.177429 
	C238.820953,640.974915 239.175232,640.970398 239.529495,640.965942 
	C234.914719,645.922852 230.299957,650.879761 225.387177,655.984619 
	C226.513962,653.864075 227.938782,651.595581 229.950989,649.035522 
	C231.357315,647.817322 232.176254,646.890564 232.995209,645.963806 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M185.361588,703.947266 
	C185.022110,699.053833 188.090912,696.163025 192.525726,693.956848 
	C190.471390,697.217651 188.053680,700.492310 185.361588,703.947266 
z"/>
<path fill="#311ECC" opacity="1.000000" stroke="none" 
	d="
M899.360840,833.043945 
	C904.464539,833.341614 909.926331,833.804871 915.674866,834.502319 
	C910.547424,834.227539 905.133179,833.718567 899.360840,833.043945 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M711.991150,584.611328 
	C707.376465,584.880432 706.463684,581.025024 704.978516,577.377563 
	C707.278992,579.438232 709.571960,581.868469 711.991150,584.611328 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M720.289246,592.183167 
	C717.639709,590.179810 714.850830,587.888733 712.062500,585.242920 
	C714.758667,587.223999 717.454285,589.559692 720.289246,592.183167 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M217.316620,664.906860 
	C219.148026,662.374878 221.289825,659.729980 223.799438,657.098938 
	C221.987167,659.673096 219.807083,662.233521 217.316620,664.906860 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M248.216812,632.645508 
	C248.778000,628.722717 251.439880,626.381042 255.451172,624.942261 
	C253.384506,627.474304 250.960831,630.024353 248.216812,632.645508 
z"/>
<path fill="#3728C8" opacity="1.000000" stroke="none" 
	d="
M801.655823,770.190796 
	C801.043701,772.631470 800.133240,775.222290 798.942383,777.930054 
	C799.560547,775.478333 800.458984,772.909607 801.655823,770.190796 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M240.975037,637.958008 
	C242.972824,636.283447 244.970612,634.608826 247.310669,632.904785 
	C245.683716,634.918762 243.714493,636.962158 241.380981,638.792480 
	C241.002808,638.372253 240.988922,638.165100 240.975037,637.958008 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M193.470795,693.344238 
	C193.250488,692.803467 193.347473,692.127014 193.957016,691.052429 
	C194.644775,690.094910 194.819992,689.535522 194.995178,688.976074 
	C195.572830,688.708679 196.150482,688.441345 197.053848,688.280396 
	C196.182419,689.994019 194.985260,691.601318 193.470795,693.344238 
z"/>
<path fill="#2613CE" opacity="1.000000" stroke="none" 
	d="
M240.640076,638.009155 
	C240.988922,638.165100 241.002808,638.372253 241.058533,638.887756 
	C241.044113,639.370972 240.987869,639.545837 240.600281,639.949341 
	C240.124619,640.288086 240.027786,640.430481 239.978424,640.605225 
	C239.978424,640.605225 239.851471,640.906372 239.690491,640.936157 
	C239.175232,640.970398 238.820953,640.974915 238.199341,640.975403 
	C238.723022,640.001038 239.514069,639.030701 240.640076,638.009155 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M240.145752,640.619751 
	C240.027786,640.430481 240.124619,640.288086 240.387939,640.125000 
	C240.637146,640.326660 240.572540,640.514099 240.145752,640.619751 
z"/>
<path fill="#3222CC" opacity="1.000000" stroke="none" 
	d="
M224.366425,657.106689 
	C224.256866,656.855286 224.433899,656.545898 224.869461,656.141907 
	C224.969650,656.381042 224.811325,656.714905 224.366425,657.106689 
z"/>
<path fill="#2613CE" opacity="1.000000" stroke="none" 
	d="
M161.765625,733.036987 
	C160.789246,733.371216 159.552505,733.967773 158.314987,733.969421 
	C120.547607,734.019104 82.780159,734.012756 45.012741,733.993713 
	C44.202198,733.993347 43.391777,733.742737 42.756756,733.313354 
	C82.282555,733.010742 121.632904,733.003662 160.983246,732.996582 
	C161.157135,733.005066 161.331024,733.013611 161.765625,733.036987 
z"/>
<path fill="#3319FE" opacity="1.000000" stroke="none" 
	d="
M160.690826,732.700928 
	C121.632904,733.003662 82.282555,733.010742 42.463051,733.015015 
	C42.073093,732.687500 42.245949,732.424316 42.722000,732.108521 
	C80.282280,731.999451 117.633034,731.999146 154.983749,732.026550 
	C156.788727,732.027893 158.593536,732.273438 160.690826,732.700928 
z"/>
<path fill="#2613CE" opacity="1.000000" stroke="none" 
	d="
M232.626282,645.986694 
	C232.176254,646.890564 231.357315,647.817322 230.249817,648.869019 
	C230.726639,647.999207 231.491989,647.004395 232.626282,645.986694 
z"/>
<path fill="#2613CE" opacity="1.000000" stroke="none" 
	d="
M175.678558,714.994629 
	C175.522522,715.298767 175.071243,715.637512 174.325470,716.009705 
	C174.481766,715.705200 174.932526,715.367249 175.678558,714.994629 
z"/>
<path fill="#2613CE" opacity="1.000000" stroke="none" 
	d="
M194.753891,689.150146 
	C194.819992,689.535522 194.644775,690.094910 194.220139,690.822266 
	C194.151337,690.434814 194.331970,689.879578 194.753891,689.150146 
z"/>
<path fill="#2613CE" opacity="1.000000" stroke="none" 
	d="
M213.784760,666.121582 
	C213.911087,666.336792 213.728897,666.602783 213.246719,666.929688 
	C213.104523,666.708984 213.281021,666.436279 213.784760,666.121582 
z"/>
<path fill="#E8EDFC" opacity="1.000000" stroke="none" 
	d="
M1006.631836,803.979248 
	C1011.642761,803.805969 1013.922546,806.026306 1013.779236,810.555420 
	C1013.630798,815.247559 1010.746704,816.716125 1006.473267,816.634033 
	C1001.356750,816.535706 996.236877,816.610840 990.689209,816.610840 
	C990.689209,812.555786 990.689209,808.681458 990.689209,803.979065 
	C995.739746,803.979065 1000.957947,803.979065 1006.631836,803.979248 
z"/>
<path fill="#E9EDFD" opacity="1.000000" stroke="none" 
	d="
M1010.728210,778.344238 
	C1013.504944,785.321411 1011.385559,788.785156 1004.662903,788.866516 
	C1000.053772,788.922302 995.443359,788.877197 990.565308,788.877197 
	C990.565308,784.479187 990.565308,780.584290 990.565308,775.196533 
	C997.484802,776.171265 1003.991882,777.087891 1010.728210,778.344238 
z"/>
<path fill="#EAFCF4" opacity="1.000000" stroke="none" 
	d="
M516.553955,777.496460 
	C521.871521,777.099670 526.861328,776.946472 531.848267,777.012329 
	C535.877686,777.065491 537.884766,779.498596 538.090881,783.328674 
	C538.302124,787.253174 536.278381,789.816162 532.382202,790.141846 
	C527.915527,790.515198 523.392822,790.532898 518.919800,790.271484 
	C517.921326,790.213196 516.338623,788.422424 516.218750,787.301514 
	C515.885010,784.181763 516.178894,780.994995 516.553955,777.496460 
z"/>
<path fill="#EFFBF7" opacity="1.000000" stroke="none" 
	d="
M445.950012,816.391907 
	C434.560577,818.190979 427.803101,816.097656 426.138275,808.356750 
	C424.579956,801.110962 424.710907,793.131775 425.916229,785.773132 
	C426.970612,779.336182 433.814148,775.947144 440.655334,776.348816 
	C448.125366,776.787415 453.545105,780.592896 454.590912,787.585205 
	C455.464325,793.425171 455.362701,799.523560 454.822693,805.427917 
	C454.361755,810.468689 451.308228,814.311768 445.950012,816.391907 
z"/>
<path fill="#E1F7EB" opacity="1.000000" stroke="none" 
	d="
M93.428482,795.342041 
	C94.863472,790.575867 96.163605,786.188049 97.463745,781.800232 
	C97.828430,781.769165 98.193115,781.738098 98.557800,781.707031 
	C100.949142,789.790100 103.340492,797.873108 105.908005,806.551636 
	C100.449539,806.551636 95.612900,806.551636 90.108978,806.551636 
	C91.190781,802.872314 92.242195,799.296387 93.428482,795.342041 
z"/>
</g>
</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
